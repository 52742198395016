<template>
  <div class="home">
    <div class="home-body">
      <div class="home-list bg-purple" :class="navBarShow?'home-list-no-wechat':''" @click="$router.push('/transaction')">
        <img class="home-list-img" src="../assets/img/icon-transaction.png" />
        <div class="home-list-title">{{ $t("交易数据") }}</div>
      </div>
      <div class="home-list bg-orange" :class="navBarShow?'home-list-no-wechat':''" @click="toCompanySearch">
        <img class="home-list-img" src="../assets/img/icon-supplier.png" />
        <div class="home-list-title">{{ companyMenu }}</div>
      </div>
      <div class="home-list bg-blue" :class="navBarShow?'home-list-no-wechat':''" @click="toPc">
        <img class="home-list-img" src="../assets/img/icon-market.png" />
        <div class="home-list-title">{{ $t("市场信息") }}</div>
      </div>
      <div class="home-list bg-green" :class="navBarShow?'home-list-no-wechat':''" @click="$router.push('/erpanel')">
        <img class="home-list-img" src="../assets/img/icon-erp.png" />
        <div class="home-list-title">{{ $t("ERP") }}</div>
      </div>
      <div class="home-list bg-yellow" :class="navBarShow?'home-list-no-wechat':''" @click="$router.push('/service')">
        <img class="home-list-img" src="../assets/img/icon-service.png" />
        <div class="home-list-title">{{ $t("客户服务") }}</div>
      </div>
      <div class="home-list bg-brown" :class="navBarShow?'home-list-no-wechat':''" @click="toPc">
        <img class="home-list-img" src="../assets/img/icon-system.png" />
        <div class="home-list-title">{{ $t("公司与系统") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "../utils/utils";
import { Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      navBarShow:false,
      companyMenu:this.$t('供应商搜索'),
    };
  },
  mounted(){
    if (!utils.isWechat()) { // 是否微信,不是则会显示顶部导航栏，内容高度变化
      this.navBarShow = true;
    }
    this.companyMenu = this.$store.state.identity==1?this.$t('供应商搜索'):this.$t('客户开发');
  },
  methods: {
    toPc() {
      Toast(this.$t("请到电脑端查看"));
    },
    toCompanySearch(){
      let nowIdentity = this.$store.state.identity;
      if(nowIdentity==1){
        this.$router.push('/supplier');
      }else{
        this.$router.push('/customer');
      }
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-body {
  margin: auto;
  padding: 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home-list {
  word-break: break-all;
  width: 42.5vw;
  height: calc(100vh / 3 - 20vw / 3 - 50px / 3);
  box-sizing: border-box;
  margin-bottom: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 8px 0 #ccc;
  justify-content: center;
  border-radius: 5px;
}
.home-list-no-wechat {
  height: calc(100vh / 3 - 20vw / 3 - 96px / 3);
}
.home-list:nth-last-child(1),
.home-list:nth-last-child(2) {
  margin: 0;
}
.home-list-img {
  width: 40px;
  height: 40px;
}
.home-list-title {
  margin-top: 3.5vw;
  color: white;
}
.bg-purple {
  background: linear-gradient(to right top, #d587fe, #a64ff3);
}
.bg-orange {
  background: linear-gradient(to right top, #f67f24, #fa3349);
}
.bg-blue {
  background: linear-gradient(to right top, #1785f9, #6aa9f7);
}
.bg-green {
  background: linear-gradient(to right top, #39c87b, #39c87b);
}
.bg-yellow {
  background: linear-gradient(to right top, #fdd303, #f4ad31);
}
.bg-brown {
  background: linear-gradient(to right top, #fd9874, #fd9874);
}
</style>